<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Determine which of the following molecules have a <b>net</b> dipole moment. Select all that
        apply.
      </p>
      <div v-for="option in options" :key="option.value" class="pl-8">
        <v-checkbox
          v-model="inputs.input1"
          class="d-inline-block my-0 py-0"
          :value="option.value"
        />
        <chemical-latex :content="option.molecule" />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question59',
  components: {ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
      },
      options: [
        {molecule: 'CH2Cl2', value: '1'},
        {molecule: 'CCl4', value: '2'},
        {molecule: '(CH3)2O', value: '3'},
        {molecule: 'CO2', value: '4'},
        {molecule: 'BF3', value: '5'},
        {molecule: 'SF6', value: '6'},
      ],
    };
  },
};
</script>
